import React from 'react';

import Success from 'components/pages/saas/activate/success';
import BasicLayout from 'layouts/basic';
import SeoMetadata from 'utils/seo-metadata';

const ActivateSuccessPage = () => (
  <BasicLayout pageMetadata={SeoMetadata.saas.activate.success}>
    <Success />
  </BasicLayout>
);

export default ActivateSuccessPage;
