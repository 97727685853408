import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const Success = () => (
  <Container>
    <Heading tag="h3" size="lg" theme="primary" className="text-center">
      Thank you
    </Heading>
    <div>
      <p className="mt-4 text-center text-gray-3">We will be in touch with you shortly.</p>
    </div>
  </Container>
);

export default Success;
